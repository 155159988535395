import React, { useEffect, useState } from 'react';
// import emailjs from 'emailjs-com';
import './connectnow.css';
import { toast } from "react-toastify";

const notifyB = (msg) => toast.success(msg);
const notifyA = (msg) => toast.error(msg);

const ConnectNow = ({ isOpen, onClose }) => {
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
        country: "",
        city: "",
        companyName: "",
        companySize: "",
        message: ""
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden'; // Disable scrolling
        } else {
            document.body.style.overflow = 'auto'; // Enable scrolling
        }

        return () => {
            document.body.style.overflow = 'auto'; // Reset when component unmounts
        };
    }, [isOpen]);

    // Handle form submission
    // const handleSubmit = (e) => {
    //     e.preventDefault();

    //     emailjs.send(
    //         "service_2wqn3ze", 
    //         "template_4chyix7",
    //         {
    //             ...formData,
    //         },
    //         "vF2GvwR9Or4yS-igh"
    //     )
    //     .then((result) => {
    //         console.log(result.text);
    //         alert("Form successfully submitted! We'll be in touch.");
    //         onClose();
    //     }, (error) => {
    //         console.log(error.text);
    //         alert("There was an error submitting the form.");
    //     });
    // };
    const handleSubmit = (e) => {
        e.preventDefault();
        onClose(); 
        const form = new FormData();
        Object.keys(formData).forEach(key => form.append(key, formData[key]));

        fetch("https://formspree.io/f/mpwaevwb", {
            method: "POST",
            body: form,
            headers: {
                'Accept': 'application/json',
            },
        }).then(response => {
            if (response.ok) {
                notifyB("Appointment send successfully, We Will get back to you shortly!");
                // setFormData({ /* Reset form if needed */ });
                setFormData({ ...formData, firstName: "", lastName: "", email:"", mobile: "", country: "", city: "", companyName: "", companySize: "", message: "" })

            } else {
                notifyA("Something went wrong, please try again later.");
                setFormData({ ...formData, firstName: "", lastName: "", email:"", mobile: "", country: "", city: "", companyName: "", companySize: "", message: "" })
            }
        });
    };

    

    if (!isOpen) return null;

    return (
        <div className='connectus-container' onClick={onClose}>
            <div className='inside-container' onClick={(e) => e.stopPropagation()}>
                <div className='cross' onClick={onClose}>
                    <i className="fa-solid fa-xmark"></i>
                </div>
                <h1 className="connect-heading">Connect <span>Now</span></h1>
                <form onSubmit={handleSubmit}>
                    <div className='inside-input'>
                        <div className='input'>
                            <p>First Name<span><i className="fa-solid fa-star-of-life"></i></span></p>
                            <input name="firstName" value={formData.firstName} onChange={handleChange} />
                        </div>
                        <div className='input'>
                            <p>Last Name<span><i className="fa-solid fa-star-of-life"></i></span></p>
                            <input name="lastName" value={formData.lastName} onChange={handleChange} />
                        </div>
                    </div>
                    <div className='inside-input'>
                        <div className='input'>
                            <p>Work Email<span><i className="fa-solid fa-star-of-life"></i></span></p>
                            <input name="email" value={formData.email} onChange={handleChange} />
                        </div>
                        <div className='input'>
                            <p>Mobile Number<span><i className="fa-solid fa-star-of-life"></i></span></p>
                            <input name="mobile" value={formData.mobile} onChange={handleChange} />
                        </div>
                    </div>
                    <div className='inside-input'>
                        <div className='input'>
                            <p>Country<span><i className="fa-solid fa-star-of-life"></i></span></p>
                            <input name="country" value={formData.country} onChange={handleChange} />
                        </div>
                        <div className='input'>
                            <p>City<span><i className="fa-solid fa-star-of-life"></i></span></p>
                            <input name="city" value={formData.city} onChange={handleChange} />
                        </div>
                    </div>
                    <div className='inside-input'>
                        <div className='input'>
                            <p>Company Name<span><i className="fa-solid fa-star-of-life"></i></span></p>
                            <input name="companyName" value={formData.companyName} onChange={handleChange} />
                        </div>
                        <div className='input'>
                            <p>Company Size<span><i className="fa-solid fa-star-of-life"></i></span></p>
                            <input name="companySize" value={formData.companySize} onChange={handleChange} />
                        </div>
                    </div>
                    <div className='inside-input'>
                        <div className='input message'>
                            <p>Message<span><i className="fa-solid fa-star-of-life"></i></span></p>
                            <input name="message" value={formData.message} onChange={handleChange} />
                        </div>
                    </div>
                    <p className='important'>Fields marked with * are mandatory.</p>
                    <button className='submit' type="submit">Submit</button>
                </form>
            </div>
        </div>
    );
};

export default ConnectNow;
