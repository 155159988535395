import React, { useState } from "react";
import "./careers.css";
import Future from "../../assets/careers/futuretogether.png";
import Parties from "../../assets/careers/officeparties.png";
import Annual from "../../assets/careers/annualtrips.png";
import Weekly from "../../assets/careers/weekly.png";
import WorkLife from "../../assets/careers/lifebalance.png";
import Technologies from "../../assets/careers/technologies.png";
import Health from "../../assets/careers/healthinsurance.png";
import { Accordion } from "flowbite-react";
import { RiMapPin2Fill, RiSuitcase2Fill } from "@remixicon/react";
import { Modal } from "flowbite-react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "swiper/css/thumbs";
import { Autoplay, FreeMode, Pagination, Thumbs } from "swiper/modules";
import { toast } from "react-toastify";

const notifyB = (msg) => toast.success(msg);
const notifyA = (msg) => toast.error(msg);
function Careers() {
  const [openModal, setOpenModal] = useState(false);
  const [currentJob, setCurrentJob] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    resumeLink: "", // Added for resume link
    highestQualification: "",
    experience: "",
    remarks: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpenModal(false);
    const form = new FormData();
    Object.keys(formData).forEach((key) => form.append(key, formData[key]));

    fetch("https://formspree.io/f/mpwaevwb", {
      method: "POST",
      body: form,
      headers: {
        Accept: "application/json",
      },
    }).then((response) => {
      if (response.ok) {
        notifyB(
          "Appointment send successfully, We Will get back to you shortly!"
        );
        // setFormData({ /* Reset form if needed */ });
        setFormData({
          ...formData,
          name: "",
          email: "",
          phone: "",
          resumeLink: "",
          highestQualification: "",
          experience: "",
          remarks: "",
        });
      } else {
        notifyA("Something went wrong, please try again later.");
        setFormData({
          ...formData,
          name: "",
          email: "",
          phone: "",
          resumeLink: "",
          highestQualification: "",
          experience: "",
          remarks: "",
        });
      }
    });
  };
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const jobs = [
    {
      title: "Senior MERN Stack Developer – Travel Industry Expert ",
      description:
        "Join our team to design scalable website that meet our clients needs.",
      location: "Mumbai, Dubai, London",
      type: "Remote, On-Site",
      details: `3+ years of experience as a Senior MERN Stack Developer in a travel industry environment. Proven expertise in building scalable applications using the MERN stack (MongoDB, Express, React, Node.js) and Laravel. In-depth knowledge of travel APIs (Amadeus, Sabre, GDS) and CRM systems. Experience in cloud platforms like AWS, Azure, or GCP, with proficiency in serverless architecture and containerization (Docker). Skilled in front-end technologies (JavaScript, HTML5, CSS3) and RESTful APIs. Strong knowledge of Agile methodologies, version control (Git), and security best practices for web development`,
    },
    {
      title: "Cloud Solutions Architect",
      description:
        "Join our team to design scalable cloud solutions that meet our clients needs.",
      location: "Mumbai, Dubai, London",
      type: "Remote, On-Site",
      details: `2+ years of experience as a DevOps/Cloud Engineer in a cloud-based environment. 
                Proven experience with AWS or GCP cloud platforms, including their core services 
                (experience with Azure is a plus). In-depth knowledge of Infrastructure as Code 
                (IaC) tools like Terraform or Ansible (or equivalent). Expertise in CI/CD pipelines 
                and tools like Jenkins, GitLab CI/CD, or CircleCI. Solid understanding of containerization 
                technologies like Docker and Kubernetes. Experience with scripting languages like Python, 
                Bash, or PowerShell. Strong knowledge of Linux system administration. Strong understanding 
                of system monitoring tools (Prometheus, Grafana, Datadog, or equivalents).`,
    },

    {
      title: "Project Manager",
      description:
        "Oversee project planning, execution, and delivery. Coordinate between teams, manage timelines, budgets, and ensure projects meet client expectations and deadlines",
      location: "Mumbai, Dubai, London",
      type: "Remote, On-Site",
      details: `2+ years of experience as a DevOps/Cloud Engineer in a cloud-based environment. 
                Proven experience with AWS or GCP cloud platforms, including their core services 
                (experience with Azure is a plus). In-depth knowledge of Infrastructure as Code 
                (IaC) tools like Terraform or Ansible (or equivalent). Expertise in CI/CD pipelines 
                and tools like Jenkins, GitLab CI/CD, or CircleCI. Solid understanding of containerization 
                technologies like Docker and Kubernetes. Experience with scripting languages like Python, 
                Bash, or PowerShell. Strong knowledge of Linux system administration. Strong understanding 
                of system monitoring tools (Prometheus, Grafana, Datadog, or equivalents).`,
    },

    {
      title: "UI/UX Designer",
      description:
        "Create user-centric designs for websites and applications. Focus on enhancing user experience through intuitive design, wireframes, and prototypes, while maintaining brand consistency.",
      location: "Mumbai, Dubai, London",
      type: "Remote, On-Site",
      details: `2+ years of experience as a DevOps/Cloud Engineer in a cloud-based environment. 
                Proven experience with AWS or GCP cloud platforms, including their core services 
                (experience with Azure is a plus). In-depth knowledge of Infrastructure as Code 
                (IaC) tools like Terraform or Ansible (or equivalent). Expertise in CI/CD pipelines 
                and tools like Jenkins, GitLab CI/CD, or CircleCI. Solid understanding of containerization 
                technologies like Docker and Kubernetes. Experience with scripting languages like Python, 
                Bash, or PowerShell. Strong knowledge of Linux system administration. Strong understanding 
                of system monitoring tools (Prometheus, Grafana, Datadog, or equivalents).`,
    },
    // Add more job objects here
  ];

  const slidesData = [
    {
      imgSrc: Annual,
      gradient: "from-[#173953] to-[#004B84]",
      title: "Work Environment and Culture",
      description:
        "Join a vibrant and collaborative workspace driven by innovation. Our culture fosters creativity, teamwork, and a passion for excellence.",
    },
    {
      imgSrc: Weekly,
      gradient: "from-[#F9E181] to-[#D2B43A]",
      title: "Professional Development",
      description: "Accelerate your career with tailored growth opportunities and learning programs. From mentorship to workshops, we invest in your future success.",
    },
    {
      imgSrc: WorkLife,
      gradient: "from-[#6ADB6A] to-[#1F8D1F]",
      title: "Perks & Benefits",
      description:
        "Enjoy a comprehensive benefits package designed with your well-being in mind. We offer competitive salaries, flexible hours, health coverage, and more.",
    },
    {
      imgSrc: Technologies,
      gradient: "from-[#68DBF2] to-[#509CF5]",
      title: "Impactful Work",
      description:
        "Make a difference by working on cutting-edge projects that shape industries. Contribute to groundbreaking solutions in AI, Blockchain, and beyond.",
    },
    {
      imgSrc: Health,
      gradient: "from-[#002029] to-[#116A82]",
      title: "Diversity & Inclusion",
      description:
        "Be part of an inclusive team that values diverse perspectives and backgrounds.Collaborate with global talent in a supportive, welcoming environment.",
    },
  ];

  const openJobModal = (jobTitle) => {
    setCurrentJob(jobTitle);
    setOpenModal(true);
  };

  // const handleInputChange = (e) => {
  //     const { name, value } = e.target;
  //     setFormData({ ...formData, [name]: value });
  // };

  return (
    <div>
      <div className="container mx-auto gap-5 p-5  mb-10 lg:px-15">
        <div className="grid grid-cols-1 justify-between items-center gap-5 md:grid-cols-2">
          <div className="flex flex-col gap-5">
            <div className="text-[28px] leading-[36px] lg:text-[45px] mt-20 lg:leading-[60px] font-light">
              <span className="font-bold text-primary">Join Us </span> and{" "}
              <span className="font-bold text-seconday">
                Shape the <br />
                Future Together
              </span>
            </div>
            <div className="text-paragraph font-normal text-base md:w-[90%] md:mb-10">
              With over{" "}
              <span className="text-primary font-bold">15 successful</span> web
              development projects under our belt, we pride ourselves on
              delivering exceptional digital solutions that drive results.
            </div>
          </div>
          <div className="mt-28">
            <img src={Future} alt="techaag" className="mx-auto" />
          </div>
        </div>
      </div>

      <div>
        <div className="container mx-auto gap-5 p-5 md:mt-20 mb-10 lg:p-16">
          <div className="grid grid-cols-1 gap-5 justify-center text-center">
            <div className="text-[32px] leading-[40px] mb-5 sm:text-[35px] sm:leading-[55px]">
              What <span className="text-seconday font-bold">We Offer</span>
            </div>

            {/* Swiper Slider */}
            <div className="grid grid-cols-1 justify-center">
              <Swiper
                slidesPerView={1}
                spaceBetween={30}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                freeMode={true}
                pagination={{
                  clickable: true,
                }}
                modules={[Autoplay, FreeMode, Pagination, Thumbs]}
                className="mySwiper"
              >
                {slidesData.map((slide, index) => (
                  <SwiperSlide key={index}>
                    <div
                      className="flex flex-col items-center gap-5 h-full p-5"
                      style={{ border: "2px solid #eee" }}
                    >
                      <div
                        className={`p-4 rounded-md bg-gradient-to-r ${slide.gradient}`}
                      >
                        <img
                          src={slide.imgSrc}
                          alt={slide.title}
                          className="w-20 h-20 object-contain"
                        />
                      </div>
                      <div className="text-lg mb-5">
                        <span className="font-bold">{slide.title}</span>
                        <div className="text-paragraph text-sm">
                          {slide.description}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>

      {/* Careers Section */}
      <div>
        <div className="container mx-auto flex flex-col gap-10 p-5 mb-10 lg:p-16">
          <div className="grid grid-cols-1 gap-5 justify-between md:grid-cols-2">
            <div className="text-[32px] leading-[40px] sm:text-[35px] sm:leading-[45px] font-light">
              If you think you can make it, <br />
              <span className="font-bold text-primary">
                {" "}
                Check Openings
              </span>{" "}
              and <span className="font-bold text-seconday"> Apply</span>
            </div>
            <div className="flex flex-col gap-5 lg:px-5 md:order-last"></div>
          </div>

          <Accordion>
            {jobs.map((job, index) => (
              <Accordion.Panel key={index}>
                <Accordion.Title>
                  <div>{job.title}</div>
                  <div className="text-paragraph font-light">
                    {job.description}
                  </div>
                  <div className="text-paragraph flex gap-2 mt-2 items-center font-light">
                    <RiMapPin2Fill size={20} className="my-icon" />
                    {job.location}
                    <RiSuitcase2Fill size={20} className="my-icon" />
                    {job.type}
                  </div>
                </Accordion.Title>
                <Accordion.Content>
                  <div className="font-bold">About Position:</div>
                  <div className="mb-3">{job.details}</div>
                  <div className="my-3 flex justify-center items-center">
                    <button
                      onClick={() => openJobModal(job.title)}
                      className="bg-primary w-fit text-white px-4 py-3 rounded-lg"
                    >
                      Apply Now
                    </button>
                  </div>
                </Accordion.Content>
              </Accordion.Panel>
            ))}
          </Accordion>
        </div>
      </div>

      {/* Application Modal */}
      <Modal show={openModal} onClose={() => setOpenModal(false)}>
        <Modal.Header>
          <span className="font-bold text-primary">Apply for</span>{" "}
          <span className="text-seconday font-bold">{currentJob}</span>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit} className="space-y-8">
            <div>
              <label htmlFor="name" className="block mb-2 text-sm font-medium">
                Your Name
              </label>
              <input
                type="text"
                id="name"
                className="shadow-sm bg-gray-50 border border-gray-300 text-sm rounded-lg w-full p-2.5"
                placeholder="Your Name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>

            <div>
              <label htmlFor="email" className="block mb-2 text-sm font-medium">
                Email
              </label>
              <input
                type="email"
                id="email"
                className="shadow-sm bg-gray-50 border border-gray-300 text-sm rounded-lg w-full p-2.5"
                placeholder="abc@mail.com"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>

            <div>
              <label htmlFor="phone" className="block mb-2 text-sm font-medium">
                Mobile Number
              </label>
              <input
                type="number"
                id="phone"
                className="shadow-sm bg-gray-50 border border-gray-300 text-sm rounded-lg w-full p-2.5"
                placeholder="1234567890"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>

            <div>
              <label
                htmlFor="experience"
                className="block mb-2 text-sm font-medium"
              >
                No of Years Experience
              </label>
              <input
                type="number"
                id="experience"
                className="shadow-sm bg-gray-50 border border-gray-300 text-sm rounded-lg w-full p-2.5"
                placeholder="Years of Experience"
                value={formData.experience}
                onChange={handleChange}
                required
              />
            </div>

            {/* Updated resume field */}
            <div>
              <label
                htmlFor="resumeLink"
                className="block mb-2 text-sm font-medium"
              >
                Resume Link
              </label>
              <input
                type="url"
                id="resumeLink"
                className="shadow-sm bg-gray-50 border border-gray-300 text-sm rounded-lg w-full p-2.5"
                placeholder="https://drive.google.com/your-resume-link"
                value={formData.resumeLink}
                onChange={handleChange}
                required
              />
            </div>

            <div>
              <label
                htmlFor="highestQualification"
                className="block mb-2 text-sm font-medium"
              >
                Highest Qualification
              </label>
              <input
                type="text"
                id="highestQualification"
                className="shadow-sm bg-gray-50 border border-gray-300 text-sm rounded-lg w-full p-2.5"
                placeholder="Bachelor"
                value={formData.highestQualification}
                onChange={handleChange}
                required
              />
            </div>

            <div>
              <label
                htmlFor="remarks"
                className="block mb-2 text-sm font-medium"
              >
                Remarks
              </label>
              <input
                type="text"
                id="remarks"
                className="shadow-sm bg-gray-50 border border-gray-300 text-sm rounded-lg w-full p-2.5"
                placeholder="Your Remarks"
                value={formData.remarks}
                onChange={handleChange}
                required
              />
            </div>

            <button
              type="submit"
              className="py-3 mx-auto rounded px-5 text-sm font-medium text-center bg-primary text-white w-full hover:bg-primary-800"
            >
              Submit
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Careers;
