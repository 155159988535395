import React, { useState } from 'react';
import LetsConnect from '../LetsConnect/LetsConnect';

function Section9() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
  return (
    <div>
      <div className="container mx-auto gap-5 relative p-5 md: mb-10 py-20 lg:px-15">
        <div className="grid grid-cols-1 gap-5 justify-between items-center md:grid-cols-2">
          <div className="font-bold text-xl text-center w-full md:text-left md:w-3/4 md:text-3xl">
            Choose the best developers and designers around!
          </div>
          <div className="flex justify-center md:justify-end">
            <button onClick={openModal} className="bg-primary w-fit text-white py-3 px-10 rounded-lg letsconnectbtn">
              Let’s Connect
            </button>
            <LetsConnect isOpen={isModalOpen} onClose={closeModal} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section9;
